<template>
  <v-row>
    <v-col cols="5" class="mt-6">
      <v-card flat class="pa-4">
        <v-card-title class="d-block px-0">
          <back-button-component/>
          <span class="font-weight-bold d-block">Report dated {{ form.date }}</span>
        </v-card-title>
        <v-card>
          <v-card-text class="pa-8">
            <v-card flat class="rounded-lg" :loading="loading">
              <div class="d-flex justify-center flex-column pa-5">
                <span class="font-weight-bold">1. How do you rate your day?</span>
              </div>
              <v-card-text class="pa-5 d-flex align-baseline justify-space-between">
                <div class="d-flex justify-center flex-column">
                  <span class="mx-auto mb-4">Day was</span>
                  <template v-if="form.status">
                    <span class="display-2 mt-4" :style="`color: ${form.status.color}`">{{ form.status.value }}</span>
                  </template>
                </div>
                <div class="d-flex justify-center flex-column">
                  <span class="mx-auto mb-4">Day Rate</span>
                  <v-progress-circular width="15"
                                       size="90"
                                       color="accent"
                                       v-model="dayRate">
                    <strong>{{ form.day_rate }}</strong>
                  </v-progress-circular>
                </div>
                <div class="d-flex justify-center flex-column">
                  <span class="mx-auto mb-4">Effort Rate</span>
                  <v-progress-circular width="15"
                                       size="90"
                                       color="secondary"
                                       v-model="dayEffort">
                    <strong>{{ form.effort_rate }}</strong>
                  </v-progress-circular>
                </div>
              </v-card-text>
              <v-card-text class="pa-5">
                <label class="font-weight-bold d-block mb-4">Give yourself an explanation:</label>
                <div>{{ form.description }}</div>
              </v-card-text>
              <v-card-text class="pa-5">
                <label class="font-weight-bold d-block mb-4">Explain your effort</label>
                <div>{{ form.notice }}</div>
              </v-card-text>
              <v-card-text class="pa-5">
                <label class="font-weight-bold d-block mb-4 black--text">2. Top 5 priorities of the day:</label>
                <template v-for="(priority, i) of form.priorities">
                  <div class="mb-4" :key="priority.description">
                    <span class="d-block font-weight-bold black--text">Top #{{ i + 1 }} priority for tomorrow</span>
                    <span class="d-block black--text">{{ priority.description }}</span>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
  </v-row>

</template>
<script>

export default {
  name: "ShowReport",
  data () {
    return {
      loading: false,
      form: {}
    }
  },
  mounted() {
    this.getReport()
  },
  methods: {
    getReport () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/reports/${this.$route.params.id}`)
          .then(response => {
            this.form = response.data
          })
    }
  },
  computed: {
    dayEffort () {
      return (this.form.effort_rate * 100) / 10
    },
    dayRate () {
      return (this.form.day_rate * 100) / 10
    }
  }
}
</script>

<style scoped>

</style>